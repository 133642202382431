'use client'

import { GeolocationContext } from '@components/hooks/GeolocationProvider'
import { stringCoordsToGeo } from '@components/hooks/GeolocationProvider/convertGeoToCoords'
import { getAllSearchParams } from '@components/hooks/ParamsProvider/searchParams'
import { findClosestLocation } from '@modules/trials/helpers/parseLocationsFromTrial/parseTruncatedLocationsFromTrial'
import type { TrialLocationNarrow } from '@modules/trials/types/TrialAssociatedWithLocations'
import { useContext, useEffect, useState } from 'react'
import { TrialSiteImageForSiteLocation } from './TrialSiteImageForSiteLocation'

export default function TrialSiteImage({
  className,
  containerClass,
  height,
  imageIndex,
  isAboveFold = false,
  trialDigitOffset,
  trialLocations,
  width,
}: {
  className?: string
  containerClass?: string
  height?: number
  imageIndex?: number
  isAboveFold?: boolean
  trialDigitOffset: number
  trialLocations: TrialLocationNarrow[]
  width?: number
}) {
  const [userLocation, setUserLocation] = useState(
    useContext(GeolocationContext),
  )
  const { lat, lng } = getAllSearchParams()

  useEffect(() => {
    setUserLocation(stringCoordsToGeo({ lat, lng }))
  }, [lat, lng])

  const closestSiteData = findClosestLocation(trialLocations, userLocation)

  if (!closestSiteData?.location) {
    return (
      <TrialSiteImageForSiteLocation
        altText={`Image of trial facility.`}
        className={className}
        containerClass={containerClass}
        height={height}
        imageIndex={imageIndex}
        isAboveFold={isAboveFold}
        trialDigitOffset={trialDigitOffset}
        width={width}
      />
    )
  }

  return (
    <TrialSiteImageForSiteLocation
      altText={`Image of ${closestSiteData.location.facility} in ${closestSiteData.location.city}, ${closestSiteData.location.country}.`}
      className={className}
      containerClass={containerClass}
      height={height}
      imageIndex={imageIndex}
      isAboveFold={isAboveFold}
      trialDigitOffset={trialDigitOffset}
      trialLocation={closestSiteData.location}
      width={width}
    />
  )
}
