'use client'

import { GeolocationContext } from '@components/hooks/GeolocationProvider'
import { stringCoordsToGeo } from '@components/hooks/GeolocationProvider/convertGeoToCoords'
import { getAllSearchParams } from '@components/hooks/ParamsProvider/searchParams'
import { IconPrefix } from '@components/primitives/Icon/IconPrefix'
import { Text } from '@components/primitives/Text'
import { faContactCard } from '@fortawesome/pro-regular-svg-icons/faContactCard'
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons/faLocationDot'
import { faVenusMars } from '@fortawesome/pro-regular-svg-icons/faVenusMars'
import type { GeolocationWithLocationName } from '@lib/types/Geolocation'
import { getTrialAttributes } from '@modules/trials/helpers/getTrialAttributes'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import type { TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import { useContext, useEffect, useMemo, useState } from 'react'

export const KeyTrialInformation = ({
  trial,
}: {
  trial: TrialForSummaryCard
}) => {
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  } as GeolocationWithLocationName)

  const { latitude, longitude } = useContext(GeolocationContext)

  useEffect(() => {
    // Set coords from context if available, otherwise from URL
    if (latitude && longitude) setUserLocation({ latitude, longitude })
    const { lat, lng } = getAllSearchParams()
    setUserLocation(stringCoordsToGeo({ lat, lng }))
  }, [latitude, longitude])

  const trialAttributes = getTrialAttributes(trial, userLocation)

  return (
    <div className='flex w-full flex-col gap-1'>
      <div className='flex'>
        <AgeAndSexAttributes trial={trial} />
      </div>
      <Text
        className='ml-1 truncate text-sm'
        element='div'
        styleName='p-tiny'
        value={
          <IconPrefix icon={faLocationDot} iconSize='lg'>
            {trialAttributes.NearestCity}
            {trialAttributes.Distance && (
              <span className={'hidden md:flex'}>
                &nbsp;
                {trialAttributes.Distance}
              </span>
            )}
          </IconPrefix>
        }
      />
    </div>
  )
}
export const AgeAndSexAttributes = ({
  trial,
}: {
  trial: TrialForSummaryCard | TrialAssociation
}) => {
  const userLocation = useContext(GeolocationContext)
  const trialAttributes = useMemo(
    () => getTrialAttributes(trial, userLocation),
    [trial, userLocation],
  )

  return (
    <>
      <Text
        className='ml-1 mr-4 flex-none text-sm'
        element='div'
        styleName='p-tiny'
        value={
          <IconPrefix icon={faContactCard} iconSize='lg'>
            {trialAttributes.Ages}
          </IconPrefix>
        }
      />

      <Text
        className='flex-none text-sm'
        element='div'
        styleName='p-tiny'
        value={
          <IconPrefix icon={faVenusMars} iconSize='lg'>
            {trialAttributes.Sexes}
          </IconPrefix>
        }
      />
    </>
  )
}

export default KeyTrialInformation
