import isNotNull from '@lib/utilities/object/isNotNull'
import type { TrialLocationImages } from '@modules/trials/types/TrialAssociatedWithLocations'
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'

/**
 * Sorts images so that we preference site images before city and location, deterministically producing a result
 */
export const getPrioritizedImages = (
  images: TrialLocationImages,
  offset: number = images.length,
) => {
  const imagesByCategory = groupBy(images, 'category')

  const shiftedHospitalImages = isArray(imagesByCategory.HospitalImage)
    ? shiftArrayPositionRight(imagesByCategory.HospitalImage, offset)
    : null

  const cityImages = isArray(imagesByCategory.City) ? imagesByCategory.City : []
  const stateImages = isArray(imagesByCategory.State)
    ? imagesByCategory.State
    : []

  const placeImages = cityImages
    .concat(stateImages)
    .filter((image) => !image.stableDiffusionRunId)
  const shiftedPlaceImages = shiftArrayPositionRight(placeImages, offset)

  const result = [shiftedHospitalImages, shiftedPlaceImages]
    .flat()
    .filter(isNotNull)

  return result
}

function shiftArrayPositionRight<T>(array: T[], offset: number) {
  const safeOffset = offset % array.length

  return array.slice(safeOffset).concat(array.slice(0, safeOffset))
}
