import { classMerge } from '@components/utilities/classMerge'
import type { Gradients } from '@lib/colors'
import type { ReactNode } from 'react'

type GradientFillProps = {
  backgroundGradient?: Gradients
  children?: ReactNode
  className?: string
  grainy?: boolean
}

export const GradientFill = ({
  backgroundGradient,
  children = null,
  className,
  grainy = false,
}: GradientFillProps) => {
  return (
    <div
      className={classMerge(
        'rounded-lg rounded-b-none ',
        {
          'bg-linearWarm': !backgroundGradient && !grainy,
          'bg-linearWarm-grainy': !backgroundGradient && grainy,
          [`bg-${backgroundGradient}`]: backgroundGradient && !grainy,
          [`bg-${backgroundGradient}-grainy`]: backgroundGradient && grainy,
        },
        className,
      )}
    >
      {children}
    </div>
  )
}
