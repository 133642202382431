import { getAllSearchParams } from '@components/hooks/ParamsProvider/searchParams'
import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { Tooltip } from '@components/primitives/Tooltip'
import { classMerge } from '@components/utilities/classMerge'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faListCheck } from '@fortawesome/pro-solid-svg-icons/faListCheck'
import { type TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'

export const filtersToCheckInCriteria: {
  displayName: string
  filterName:
    | 'conditions'
    | 'geneticMarkers'
    | 'diseaseStageInclusion'
    | 'linesOfTherapyInclusion'
}[] = [
  { displayName: 'Subcondition', filterName: 'conditions' },
  { displayName: 'Biomarker', filterName: 'geneticMarkers' },
  {
    displayName: 'Disease Stage',
    filterName: 'diseaseStageInclusion',
  },
  {
    displayName: 'Prior Treatment',
    filterName: 'linesOfTherapyInclusion',
  },
]

export const EligibilityMatch = ({ trial }: { trial: TrialForSummaryCard }) => {
  const currentSearch = getAllSearchParams()

  const matchingFilters: { name: string; value: string }[] = []

  if (
    currentSearch.condition &&
    trial.searchConditions.includes(currentSearch.condition)
  ) {
    matchingFilters.push({
      name: 'Condition',
      value: currentSearch.condition,
    })
  }

  for (const filter of filtersToCheckInCriteria) {
    const currentValue = currentSearch[filter.filterName]
    if (currentValue) {
      for (const value of currentValue.split(',')) {
        if (trial[filter.filterName].includes(value)) {
          matchingFilters.push({
            name: filter.displayName,
            value,
          })
        }
      }
    }
  }

  const tooltipDescription = () => {
    if (matchingFilters.length < 1) {
      return 'No criteria match. You can add criteria using the filters on the left'
    }

    return (
      <>
        <div className='mb-2'>
          This trial is a match because it is looking for participants
          featuring:
        </div>
        {matchingFilters.map((matchingFilter) => (
          <div key={matchingFilter.value}>
            <span className='font-bold'>{matchingFilter.name}</span> -{' '}
            {matchingFilter.value}
          </div>
        ))}
      </>
    )
  }

  const color =
    matchingFilters.length > 1 ? 'text-success600' : 'text-neutral600'

  const bgColor = matchingFilters.length > 1 ? 'bg-success600' : 'bg-neutral600'

  return (
    <div>
      <Text
        className={classMerge('inline font-semibold', color)}
        value={`${matchingFilters.length} Criteria Met`}
      />
      <Tooltip
        description={tooltipDescription()}
        inlineTooltip
        label={<Icon className='ml-2 text-neutral600' icon={faInfoCircle} />}
      />

      <div>
        <Icon
          className={`mr-1 inline rounded-sm ${bgColor} p-0.5 text-white`}
          icon={faListCheck}
          size='xs'
        />
        <Text
          className='inline text-neutral600'
          styleName='p-small'
          value='Eligibility Criteria'
        />
      </div>
    </div>
  )
}
